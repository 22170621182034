import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import Seo from '../components/seo'
import Layout from '../layouts/layout'
import HeaderWithImage from '../components/ForBroker/HeaderWithImage'
import ContentContainer from '../components/UI/ContentContainer'
import Benefit from '../components/UI/Benefit'
import TheRightLoanForYou from '../components/ForPersonalLoans/PersonalLoanPages/TheRightLoanForYou'
import ContactHeader from '../components/UI/ContactHeader'
import ContactItem from '../components/UI/ContactItem'
import CMSImage from '../components/UI/CMSImage'
import { parseJson } from '../support'

const BrokerPage = ({ path }) => {

  const data = useStaticQuery(graphql`
    query brokerPageQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "broker" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
      contentfulPageBroker {
        heading {
          childMarkdownRemark {
            html
          }
        }
        headerImage {
          gatsbyImageData(placeholder:NONE)
          title
        }
        subheading {
          childMarkdownRemark {
            html
          }
        }
        becomeAccreditedLink {
          text
          url
          displayAs
        }
        singInLink {
          text
          url
          displayAs
        }
        descriptiveText {
          childMarkdownRemark {
            html
          }
        }
        benefits {
          header {
            childMarkdownRemark {
              html
            }
          }
          icon {
            gatsbyImageData(placeholder:NONE)
            title
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
        listofOurProducts {
          header
          icon {
            gatsbyImageData(placeholder:NONE)
            title
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          filter
        }
        nswContacts {
          email
          name
          phone
        }
        victoriaContacts {
          email
          name
          phone
        }
        qldContacts {
          email
          name
          phone
        }
      }
    }
  `)

  const pageData = data.contentfulPageBroker
  const structuredData = parseJson(data.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)


  return (
    <Layout hasGetYourRate={false}>
      <Seo 
        title="Broker Accreditation" 
        description="SocietyOne provides your clients with simple, investor funded personal loans and low rates based on their unique credit history."
        path={path}
        structuredData={structuredData}
      />
      <HeaderWithImage page={pageData} />
      <ContentContainer padding="0 0 40px" mPadding="0 24px 40px" sPadding="0 24px 20px">
        <WhyPartnerWithUs dangerouslySetInnerHTML={{
          __html: pageData.descriptiveText.childMarkdownRemark.html,
        }}/>
        <BenefitWrapper>
          <Benefit page={pageData}/>
        </BenefitWrapper>
      </ContentContainer>

      <ContentContainer background="var(--base-gray)">
        <Header>Our products</Header>
        <LoansList>
          {pageData.listofOurProducts.map((item, index) => 
            <Item to={`/personal-loan/${item.filter}`} key={index}>
              <div className="icon">
                <CMSImage
                  svg={item.icon.svg}
                  gatsbyImageData={item.icon?.gatsbyImageData}
                  file={item.icon.file}
                  alt={item.icon.title}
                />
              </div>
              <h3>{item.header}</h3>
            </Item>
          )}
        </LoansList>
      </ContentContainer>
      <TheRightLoanForYou />
      <Hr />

      <ContentContainer>
        <Wrapper>
          <Header>Contact us</Header>
          {pageData.nswContacts[0].name?.length > 0 && 
            <ContactHeader header={"NSW"}>
              {pageData.nswContacts.map((item, index) =>
                <ContactItem name={item.name} email={item.email} phone={item.phone} key={index}/>
              )}
            </ContactHeader>
          }
          {pageData.victoriaContacts[0].name?.length > 0 &&
            <ContactHeader header={"VIC/SA/TAS"}>
              {pageData.victoriaContacts.map((item, index) =>
                <ContactItem name={item.name} email={item.email} phone={item.phone} key={index}/>
              )}
            </ContactHeader>
          }
          {pageData.qldContacts[0].name?.length > 0 &&
            <ContactHeader header={"QLD"}>
              {pageData.qldContacts.map((item, index) =>
                <ContactItem name={item.name} email={item.email} phone={item.phone} key={index}/>
              )}
            </ContactHeader>
          }
          <QuestionWrapper>
            <div className="content">
              <h3>Have some questions?</h3>
              <p>Please call us on <span>1300 908 068</span> Mon – Fri 8AM-5PM, Sat & Sun 9AM-3PM. </p>
              <p>Or contact our broker support team at <a href="mailto:brokers@moneyme.com.au"><span>brokers@moneyme.com.au</span></a></p>
            </div>
          </QuestionWrapper>
        </Wrapper>
      </ContentContainer>


    </Layout>
  )
}

const WhyPartnerWithUs = styled.div`
  max-width: 960px;
  margin: 0 auto;
  padding-bottom: 30px;
`
const BenefitWrapper = styled.div`
  max-width: 960px;
  margin: 0 auto;
`
const LoansList = styled.div`
  max-width: 666px;
  margin:0 auto;
  display: grid;
  grid-gap: 40px;
  grid-template-columns: repeat(3, 1fr);

	@media screen and (max-width: 991px) {
    grid-gap: 20px;
    max-width: 688px;
	};
	@media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 352px;
	};

`
const Header = styled.h2`
  margin: 0 auto 60px;
  text-align: center;
	@media screen and (max-width: 991px) {

  };
  @media screen and (max-width: 768px) {

  };

`
const Item = styled(Link)`
  width: 200px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid rgb(225 225 225);
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all .25s  ease-in;
  background: white;
  h3 {
    margin-top: 16px;
    text-align: center;
    font-family: QuicksandSemiBold;
    font-size: 18px;
  }
  &:hover {
    transform: translateY(-8px);
    transition: all .15s  ease-in;

  }
  @media screen and (max-width: 991px) {
    width: 160px;
    height: 160px;

  };
  @media screen and (max-width: 768px) {

  };

`

const Hr = styled.hr`
  background-color: rgba(225 225 225);
  height: 1px;
  border: none;
  width: 100%;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;

`
const QuestionWrapper = styled.div`
  background: linear-gradient(115deg, rgb(38 75 101) 50%, rgb(46 84 111) 0);
	margin: 60px 30px 0px 30px;
	padding: 24px;
	width: 960px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-radius: 20px;
	@media screen and (max-width: 991px) {
		width: 100%;
	};
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: center;
	}

	.content {
		@media screen and (max-width: 991px) {
			width: 100%;
		};
		@media screen and (max-width: 768px) {
			width: 100%;
			margin: 0 auto;
		}
    span {
      text-decoration: underline;
    }

		h3 {
			margin: 0px 0px 16px 0px;
			color: rgb(255 255 255);
			@media screen and (max-width: 768px) {
				width: 100%;
				text-align: center;
        font-size: 22px;
			}

		};
		p {
			${'' /* text-align: center; */}
			color: rgb(255 255 255);
			margin: 0;
			@media screen and (max-width: 768px) {
				width: 100%;
				text-align: center;
        font-size: 15px;
			}
		};
	};

`



export default BrokerPage
